var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"/enviar"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Enviar Estudio ")],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getEstudios}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"headers":_vm.estudiosHeaders,"items":_vm.estudiosFiltrados,"loading":_vm.loading,"single-select":false,"item-key":"idEstudio","checkbox-color":"secondary","show-select":"","calculate-widths":true,"footer-props":{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
				var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.estudiosFiltrados},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.estado",fn:function(ref){
				var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.estado}})]}},{key:"item.tipoEstudio",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":item.colorTipoEstudio}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.iconTipoEstudio))]),_vm._v(" "+_vm._s(item.tipoEstudio)+" ")],1)]}},{key:"item.enviadoEn",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.enviadoEn, false, true, true))}})]}},{key:"item.acciones",fn:function(ref){
				var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary","target":"_blank","href":("https://www.google.com/maps/search/?api=1&query=" + (item.datos.latitud) + "%2C" + (item.datos.longitud)),"disabled":!item.datos.latitud || !item.datos.longitud}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-google-maps")])],1)]}}],null,true)},[_vm._v(" Abrir en Google Maps ")]),_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"primary","to":("/enviar?idEstudio=" + (item.idEstudio)),"outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Editar ")],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }